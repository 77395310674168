// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Box,
  CopyToClipboard,
  FormField,
  Input,
  Multiselect,
  Pagination,
  Popover,
  Table,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../../styles/components/table-select.scss";
import daysAgo from "../../helpers/daysAgo";
import { getMatchesCountText } from "../../helpers/getMatchesCountText";
import { isBypassesPhase2On } from "../../helpers/isBypassesPhase2On";
import {
  Alerts,
  getSelectList,
  getSelectText,
  getTagComponent,
} from "./Alerts";

export const AllBypassesTable = ({ bypasses, loading }) => {
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([]);
  const { hash } = useLocation();

  // List of table ids, also used for defining which attribute on item to sort and filter
  const username = "username";
  const directory = "directory";
  const commitId = "commitId";
  const time = "time";
  const alertType = "type";
  const reason = "noVerifyReasonType";
  const details = "noVerifyReasonDetails";
  const SEARCHABLE_COLUMNS = [username, directory, commitId, reason, details];

  useEffect(() => {
    //parse hash and verify it is a valid AlertTag
    const alertTag = hash.replace("#", "");
    const alertTagIndex = Object.values(Alerts).findIndex(
      (item) => item.id === alertTag
    );
    const tag = Object.keys(Alerts)[alertTagIndex];
    if (alertTagIndex >= 0) {
      setSelectedAlertTypes([
        {
          value: Alerts[tag].id,
          label: getTagComponent(Alerts[tag].id),
        },
      ]);
    } else {
      setSelectedAlertTypes(getSelectList());
    }
  }, []);

  // Table collection
  const {
    items,
    actions,
    collectionProps,
    filterProps,
    filteredItemsCount,
    paginationProps,
  } = useCollection(bypasses, {
    pagination: {
      pageSize: 20,
    },
    // Controls which fields the search bar applies to
    // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
    filtering: {
      filteringFunction: (item, filteringText) => {
        if (
          selectedAlertTypes.length !== getSelectList().length &&
          !selectedAlertTypes.map((alert) => alert.value).includes(item.type)
        ) {
          return false;
        }
        if (filteringText === "") {
          return true;
        }
        const filteringTextLowerCase = filteringText.toLowerCase();

        return SEARCHABLE_COLUMNS.map((key) => item[key]).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        );
      },
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: time,
        },
        isDescending: true,
      },
    },
    selection: {},
  });

  return (
    <Table
      {...collectionProps}
      items={items}
      variant="full-page"
      loading={loading}
      loadingText="Loading all bypasses..."
      stickyColumns={{ first: 4, last: 0 }}
      columnDefinitions={[
        {
          id: directory,
          header: "Directory",
          cell: (item) => item.directory,
          sortingField: directory,
        },
        {
          id: commitId,
          header: "Commit ID",
          cell: (item) => {
            return item.commitId ? (
              <CopyToClipboard
                copyButtonAriaLabel="Copy Commit ID"
                copyErrorText="Commit ID failed to copy"
                copySuccessText="Commit ID copied"
                textToCopy={item.commitId}
                variant="inline"
              />
            ) : (
              "-"
            );
          },
          sortingField: commitId,
        },
        {
          id: username,
          header: "User",
          cell: (item) => item.username,
          sortingField: username,
        },
        {
          id: time,
          header: "Commit date",
          cell: (item) => (
            <Popover
              dismissButton={false}
              position="top"
              size="small"
              content={new Date(item.time).toDateString()}
            >
              {daysAgo(item.time)}
            </Popover>
          ),
          sortingField: time,
        },
        {
          id: alertType,
          header: "Alert type",
          cell: (item) => getTagComponent(item.type),
          minWidth: 210,
          sortingField: alertType,
        },
        {
          id: reason,
          header: "Reason",
          cell: (item) => item.noVerifyReasonType || "-",
          sortingField: reason,
        },
        {
          id: details,
          header: "Details",
          cell: (item) => item.noVerifyReasonDetails || "-",
          sortingField: details,
        },
      ]}
      filter={
        <div className="input-container">
          <div className="input-filter">
            <Input
              data-testid="input-filter"
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              ariaLabel="Find bypasses"
              placeholder="Find bypasses"
              clearAriaLabel="clear"
            />
          </div>
          {isBypassesPhase2On() && (
            <div className="select-filter">
              <FormField label="Compliance alerts">
                <Multiselect
                  data-testid="compliance-alerts-filter"
                  hideTokens
                  options={getSelectList()}
                  placeholder={getSelectText(selectedAlertTypes)}
                  selectedOptions={selectedAlertTypes}
                  onChange={(event) => {
                    setSelectedAlertTypes(event.detail.selectedOptions);
                  }}
                  expandToViewport={true}
                />
              </FormField>
            </div>
          )}
          {(filterProps.filteringText ||
            selectedAlertTypes.length !== getSelectList().length) && (
            <span className="filtering-results">
              {getMatchesCountText(filteredItemsCount)}
            </span>
          )}
        </div>
      }
      columnDisplay={[
        { id: directory, visible: true },
        { id: commitId, visible: true },
        { id: username, visible: true },
        { id: time, visible: true },
        { id: alertType, visible: isBypassesPhase2On() },
        { id: reason, visible: true },
        { id: details, visible: true },
      ]}
      pagination={
        <Box float="right">
          <Pagination {...paginationProps} disabled={loading} />
        </Box>
      }
      empty="No bypasses found"
    />
  );
};
