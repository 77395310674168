import { getSelectList } from "../components/bypasses-ui/Alerts";
import { DataAccessService } from "../core/api";
import { LocalStorage } from "../core/storage";
import { User } from "../core/user";
import { BYPASSES } from "../core/user/user";
import { isBypassesPhase2On } from "./isBypassesPhase2On";

export default async function getBypasses(fetchFromCache = false) {
  try {
    if (fetchFromCache) {
      const bypasses = LocalStorage.get(BYPASSES);
      if (bypasses) {
        return bypasses;
      }
    }
    const client = new DataAccessService();
    const user = new User();
    const requestParams = {
      username: user.userId,
      type: "COMMIT_NOT_VERIFIED",
      startDate: toRFC3339Nano(
        new Date(new Date().setDate(new Date().getDate() - 89))
      ),
      endDate: toRFC3339Nano(new Date()),
    };
    const response = await client.get(`/metrics`, undefined, requestParams);

    let bypasses = response.metricsEntries || [];

    // TODO: temporary code to generate random bypass types for UI testing, remove once backend implementation is ready
    if (isBypassesPhase2On()) {
      bypasses = bypasses.map((bypass) => {
        bypass.type = [{ other: "" }, ...getSelectList()][
          Math.floor(Math.random() * (getSelectList().length + 1))
        ].value;
        return bypass;
      });
    }

    LocalStorage.set(BYPASSES, bypasses);
    return bypasses;
  } catch (err) {
    console.warn(err);
    throw err;
  }
}

function toRFC3339Nano(date) {
  const pad = (num) => (num < 10 ? "00" : num < 100 ? "0" : "");
  const isoString = date.toISOString();
  const nanoseconds = date.getMilliseconds() * 1000000;
  const padding = pad(date.getMilliseconds());
  return `${isoString.substring(
    0,
    isoString.indexOf(".")
  )}.${padding}${nanoseconds}Z`;
}
